import styled from 'styled-components'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

export const ArrowForward = styled(ArrowForwardIcon)`
  && {
    color: #fff;
    font-size: 24px;
    cursor: pointer;
  }
  background-color: ${({ theme }) => theme.colors.darkBlue};
  border-radius: 50%;
  padding: 5px;
`
