import styled from 'styled-components'

export const DetailParagraph = styled.p`
  font-size: 12px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.text};
  margin: 0.5rem 0;

  ${({ theme }) => theme.mediaQueries.maxMobileWidth} {
    margin: 0.5rem 0 3.2rem 0;
  }
`
