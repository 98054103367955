import React from 'react'
import styled from 'styled-components'
import Head from 'next/head'
import { getGithubPreviewProps, parseJson } from 'next-tinacms-github'
import { useGithubJsonForm } from 'react-tinacms-github'
import { usePlugin } from 'tinacms'
import Link from 'next/link'
import { ReactHelmet } from '../components/ReactHelmet'
import { InlineForm, InlineTextarea } from 'react-tinacms-inline'
import { renderCloudinaryImage } from '../helpers/cloudinary'
import { Hr } from '../components/atoms/Hr'
import { DetailParagraph } from '../components/atoms/DetailParagraph'
import { ArrowForward } from '../components/atoms/ArrowForward'
import { ArgyleLogo } from '../components/atoms/Icons/ArgyleLogo'
import { ModalContext } from '../containers/MemberModalContext'
import { MaxWidthWrapper } from '../components/atoms/MaxWidthWrapper'
import { OEDS } from '../components/atoms/Icons/oeds'
import { SideNavigation, IconText, TopIcon } from '../components/molecules/SideNavigation'

const homeHeaderDesktop = renderCloudinaryImage({
  imageName: 'oeds/home-header-desktop'
})

const homeHeaderMobile = renderCloudinaryImage({
  imageName: 'oeds/home-header-mobile'
})

const specificationDesktop = renderCloudinaryImage({
  imageName: 'oeds/home-specification-desktop'
})

const specificationMobile = renderCloudinaryImage({
  imageName: 'oeds/home-specification-mobile'
})

const coverage = renderCloudinaryImage({
  imageName: 'oeds/home-coverage'
})

const SubTitle = styled.h4`
  color: ${({ theme }) => theme.colors.text};
  margin: 0 0 2rem 0;
`
const HeaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightGray};
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -3rem;
  z-index: ${({ theme }) => theme.zIndex.minimal};

  ${({ theme }) => theme.mediaQueries.maxMobileWidth} {
    padding-left: 0;
    display: block;
  }
`
const Header = styled.div`
  display: inline-block;
  max-width: 70rem;
  margin: 2rem 2rem 0 0;

  ${({ theme }) => theme.mediaQueries.maxMobileWidth} {
    margin: 7rem 2rem 0 2rem;
  }
`

const StyledHeaderImg = styled.img`
  width: 376px;
  margin-left: 0em;

  ${({ theme }) => theme.mediaQueries.maxTabletWidth} {
    width: 300px;
  }

  ${({ theme }) => theme.mediaQueries.maxMobileWidth} {
    display: none;
  }
`
const StyledHeaderImgMobile = styled.img`
  width: 100%;
  margin-left: 0;
  display: none;

  ${({ theme }) => theme.mediaQueries.maxMobileWidth} {
    display: block;
  }
`

const SpecificationDesktop = styled.img`
  display: block;

  ${({ theme }) => theme.mediaQueries.maxMobileWidth} {
    display: none;
  }
`

const SpecificationMobile = styled.img`
  width: calc(100vw - 4rem);
  display: none;

  ${({ theme }) => theme.mediaQueries.maxMobileWidth} {
    display: block;
    margin: 1rem 0 2rem 0;
  }
`

const SpecificationContent = styled.div`
  position: relative;
  padding-top: 42rem;
  margin: 0 0 0 -5rem;
  display: flex;

  div[class*='StyledFocusRing'] {
    min-width: 30rem;
  }

  ${({ theme }) => theme.mediaQueries.maxTabletWidth} {
    padding-top: 35rem;
    margin: 0 0 0 0rem;
  }

  ${({ theme }) => theme.mediaQueries.maxMobileWidth} {
    padding-top: 55rem;
  }

  ${({ theme }) => theme.mediaQueries.maxMobileWidthSmall} {
    padding-top: 42rem;
  }
`
const TextWrapper = styled.div`
  display: inline-block;
  max-width: 60rem;
  margin: 5rem 2rem 0 5rem;
  ${({ theme }) => theme.mediaQueries.maxMobileWidth} {
    display: block;
    margin: 0;
  }
`

const SpecWrapper = styled.div`
  display: grid;
  grid-gap: 4rem;
  margin-bottom: 1.2rem;
  grid-auto-flow: column;
  grid-template-columns: repeat(minmax(400px, 400px));

  ${({ theme }) => theme.mediaQueries.maxMobileWidth} {
    display: block;
  }
`

const StyledHr = styled(Hr)`
  opacity: 0.1;
  margin: 0 0 1.2em 0;
  display: block;
  ${({ theme }) => theme.mediaQueries.maxMobileWidth} {
    display: none;
  }
`

const Coverage = styled.div`
  margin-top: 3.1rem;
  margin-bottom: 8rem;
  max-width: 90rem;
  padding-top: 35rem;

  ${({ theme }) => theme.mediaQueries.maxTabletWidth} {
    margin: 7.2rem 0 0 0;
    padding-top: 30rem;
  }

  ${({ theme }) => theme.mediaQueries.maxMobileWidth} {
    padding-top: 0;
  }
`

const CoverageBanner = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
  display: flex;
  position: absolute;
  right: 0;
  left: 0;
  justify-content: center;
  width: calc(100% - 4rem);
  margin: 8rem 2rem 0 2rem;

  ${({ theme }) => theme.mediaQueries.maxMobileWidth} {
    display: none;
  }
`

const CoverageBannerMobile = styled.div`
  display: none;
  background-color: ${({ theme }) => theme.colors.lightGray};
  justify-content: center;
  margin-bottom: 2rem;

  ${({ theme }) => theme.mediaQueries.maxMobileWidth} {
    display: flex;
  }
`

const CoverageImg = styled.img`
  width: 24rem;
  display: block;

  ${({ theme }) => theme.mediaQueries.maxMobileWidth} {
    width: 15rem;
    height: 15rem;
  }
`
const Membership = styled.div`
  margin-top: 5rem;

  ${({ theme }) => theme.mediaQueries.maxTabletWidth} {
    margin-left: 0;
  }
`
const MembershipLinks = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const BecomeMemberLink = styled.a`
  display: inline-block;
  color: ${({ theme }) => theme.colors.heading};
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 0.2rem;
  ${({ theme }) => theme.mediaQueries.maxMobileWidth} {
    font-size: 14px;
  }
`

const StyledArgyleLogo = styled.div`
  display: inline-block;
  margin: 0.4rem 0 0 0.5rem;
  cursor: pointer;
`

const MembershipTitle = styled.h2`
  margin: 3rem 0 1rem 0;
`

const MemberText = styled.p`
  display: inline-block;
`

const Paragraph = styled.p`
  font-size: 18px;
  font-weight: normal;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.text};
  margin: 0.5rem 0 5.5rem 0;
  max-width: 80rem;

  ${({ theme }) => theme.mediaQueries.maxMobileWidth} {
    margin: 0.5rem 0 3.2rem 0;
  }
`
const TextBlock = styled.div`
  margin-top: 20rem;
  margin-bottom: 20rem;
`
const StyledOEDSIcon = styled(TopIcon)`
  align-items: unset;
`
const Home = ({ file }) => {
  const formConfig = {
    label: 'Home Page',
    fields: [
      {
        name: 'metaTitle',
        label: 'Meta Title',
        component: 'text'
      },
      {
        name: 'metaDescription',
        label: 'Meta Description',
        component: 'textarea'
      },
      {
        name: 'metaKeyWords',
        label: 'Meta KeyWords',
        component: 'text'
      }
    ]
  }

  const [data, form] = useGithubJsonForm(file, formConfig)
  usePlugin(form)

  if (process.env.NEXT_PUBLIC_NODE_ENV === 'production') {
    return (
      <>
        <Head>
          <title>{data.metaTitle}</title>
        </Head>

        <ReactHelmet title={data.metaTitle} description={data.metaDescription} keywords={data.metaKeyWords} />
        <Link href="/">
          <IconText> Open Employment Data Standards</IconText>
        </Link>
        <Link href="/">
          <StyledOEDSIcon>
            <OEDS />
          </StyledOEDSIcon>
        </Link>
        <MaxWidthWrapper>
          <TextBlock data-hook="home-page-goodbye-text">
            <InlineForm form={form}>
              <Paragraph>
                <InlineTextarea name="goodbye.paragraph0" />
              </Paragraph>
              <Paragraph>
                <InlineTextarea name="goodbye.paragraph1" />
              </Paragraph>
              <Paragraph>
                <InlineTextarea name="goodbye.paragraph2" />
              </Paragraph>
              <Paragraph>
                <InlineTextarea name="goodbye.paragraph3" />
              </Paragraph>
            </InlineForm>
          </TextBlock>
        </MaxWidthWrapper>
      </>
    )
  }

  return (
    <>
      <Head>
        <title>{data.metaTitle}</title>
      </Head>
      <ReactHelmet title={data.metaTitle} description={data.metaDescription} keywords={data.metaKeyWords} />
      <SideNavigation />
      <MaxWidthWrapper>
        <HeaderWrapper>
          <StyledHeaderImg src={homeHeaderDesktop} />
          <Header>
            <InlineForm form={form}>
              <h1 data-hook="home-page-title">
                <InlineTextarea name="title" />
              </h1>
              <SubTitle>
                <InlineTextarea name="subTitle" />
              </SubTitle>
            </InlineForm>
            <Link href="/about">
              <ArrowForward data-hook="home-page-link-to-about-page" />
            </Link>
          </Header>
          <StyledHeaderImgMobile src={homeHeaderMobile} />
        </HeaderWrapper>
        <InlineForm form={form}>
          <SpecificationContent>
            <SpecificationDesktop src={specificationDesktop} width={266} height={628} />
            <TextWrapper>
              <h2 data-hook="home-page-specification-title">
                <InlineTextarea name="specification.title" />
              </h2>
              <p>
                <InlineTextarea name="specification.subTitle" />
              </p>
              <SpecificationMobile src={specificationMobile} />
              <StyledHr />
              <SpecWrapper>
                <div>
                  <h5>
                    <InlineTextarea name="specification.heading1" />
                  </h5>
                  <DetailParagraph>
                    <InlineTextarea name="specification.paragraph1" />
                  </DetailParagraph>
                </div>
                <div>
                  <h5>
                    <InlineTextarea name="specification.heading2" />
                  </h5>
                  <DetailParagraph>
                    <InlineTextarea name="specification.paragraph2" />
                  </DetailParagraph>
                </div>
              </SpecWrapper>
              <StyledHr />
              <SpecWrapper>
                <div>
                  <h5>
                    <InlineTextarea name="specification.heading3" />
                  </h5>
                  <DetailParagraph>
                    <InlineTextarea name="specification.paragraph3" />
                  </DetailParagraph>
                </div>
                <div>
                  <h5>
                    <InlineTextarea name="specification.heading4" />
                  </h5>
                  <DetailParagraph>
                    <InlineTextarea name="specification.paragraph4" />
                  </DetailParagraph>
                </div>
              </SpecWrapper>
              <StyledHr />
              <Link href="/specification">
                <ArrowForward data-hook="home-page-link-to-specification-page" />
              </Link>
            </TextWrapper>
          </SpecificationContent>

          <CoverageBanner>
            <CoverageImg width={240} height={243} src={coverage} alt="Coverage section banner image" />
          </CoverageBanner>
          <Coverage>
            <h2 data-hook="home-page-coverage-title">
              <InlineTextarea name="coverage.title" />
            </h2>
            <p>
              <InlineTextarea name="coverage.subTitle" />
            </p>
            <StyledHr />
            <CoverageBannerMobile>
              <CoverageImg width={15} src={coverage} alt="Coverage section banner image" />
            </CoverageBannerMobile>

            <SpecWrapper>
              <div>
                <h5>
                  <InlineTextarea name="coverage.heading1" />
                </h5>
                <DetailParagraph>
                  <InlineTextarea name="coverage.paragraph1" />
                </DetailParagraph>
              </div>
              <div>
                <h5>
                  <InlineTextarea name="coverage.heading2" />
                </h5>
                <DetailParagraph>
                  <InlineTextarea name="specification.paragraph2" />
                </DetailParagraph>
              </div>
            </SpecWrapper>
            <StyledHr />
            <Link href="/coverage">
              <ArrowForward data-hook="home-page-link-to-coverage-page" />
            </Link>
          </Coverage>
          <Membership>
            <MembershipTitle data-hook="home-page-membership-title">
              <InlineTextarea name="membership.title" />
            </MembershipTitle>
            <MembershipLinks>
              <p>Founded by</p>
              <Link href="https://argyle.com/" target="_blank" rel="noopener noreferrer">
                <StyledArgyleLogo>
                  <ArgyleLogo />
                </StyledArgyleLogo>
              </Link>
              <MemberText>
                <span>&nbsp;available for everyone.&nbsp;</span>
              </MemberText>
              <ModalContext.Consumer>
                {({ setShowModal }) => (
                  <BecomeMemberLink onClick={() => setShowModal(true)} data-hook="home-page-become-member-link">
                    Apply for membership.
                  </BecomeMemberLink>
                )}
              </ModalContext.Consumer>
            </MembershipLinks>
          </Membership>
        </InlineForm>
      </MaxWidthWrapper>
    </>
  )
}

export const getStaticProps = async function ({ preview, previewData }) {
  if (preview) {
    return getGithubPreviewProps({
      ...previewData,
      fileRelativePath: 'content/home.json',
      parse: parseJson
    })
  }

  return {
    props: {
      sourceProvider: null,
      error: null,
      preview: false,
      file: {
        fileRelativePath: 'content/home.json',
        data: (await import('../content/home.json')).default
      }
    }
  }
}

export default Home
