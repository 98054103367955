/**
 * @param {string} imageName the file name in cloudinary
 * @param {number} width optional // integer or string value, if auto cloudinary algorithm tries to adjust it best to the screen size
 * @param {boolean} renderWithTransformations optional
 * @param {string} type optional // jpg, png or any other type
 * f_auto - Dynamic format selection: load the right image type for each browser, e.g. use webp over jpg
 * c_lfill - Never output thumbnails bigger than original image, cropping parameter
 * g_auto - Automatic content-aware cropping
 * q_auto - Intelligent content-aware encoding
 * w_auto and dpr_auto - Automatic responsive images
 */

const VERSION = 'v1606234381'
export const renderCloudinaryImage = ({ imageName, width = 'auto', renderWithTransformations = false, type = 'png' }) =>
  renderWithTransformations
    ? `https://res.cloudinary.com/argyle-media/image/upload/c_lfill,w_${width},g_auto,q_auto,dpr_auto,f_auto/${VERSION}/${imageName}.${type}`
    : `https://res.cloudinary.com/argyle-media/image/upload/q_auto/${VERSION}/${imageName}.${type}`
